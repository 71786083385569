import React from "react";
import styled from "styled-components";
import { breakpoints, spacers, colors } from "../../styles/variables";

import Wrapper from "../Wrapper";

interface IconBannerProps {
  text: string;
  icon: string;
  downloadLink: string;
}

const BannerDiv = styled.div``;
const BannerFlex = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacers.s}px;

  h3 {
    color: ${colors.brand};
    word-wrap: break-word;
    hyphens: auto;
  }

  > div:first-of-type {
    text-align: center;
  }

  &.icon {
    > div:first-of-type {
      text-align: center;
      order: 2;
    }

    > div:nth-of-type(2) {
      order: 1;
      display: block;
      margin: 0 auto;
      > img {
        width: 150px;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-gap: ${spacers.m}px;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.icon {
      grid-template-columns: 1fr 150px;
      > div:first-of-type {
        order: 1;
      }
      > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        margin: 0;
        order: 2;
        display: block;
      }
    }
  }
`;

const IconBanner: React.FC<IconBannerProps> = ({
  text,
  icon,
  downloadLink,
  link,
}) => (
  <BannerDiv>
    {downloadLink ? (
      <a href={downloadLink} target="_blank">
        <Wrapper>
          <BannerFlex className={icon ? "icon" : ""}>
            <div>
              <h3>{text}</h3>
            </div>
            {icon ? (
              <div>
                <img src={icon}></img>
              </div>
            ) : (
              ""
            )}
          </BannerFlex>
        </Wrapper>
      </a>
    ) : link ? (
      <a href={"/" + link} target="_blank">
        <Wrapper>
          <BannerFlex className={icon ? "icon" : ""}>
            <div>
              <h3>{text}</h3>
            </div>
            {icon ? (
              <div>
                <img src={icon}></img>
              </div>
            ) : (
              ""
            )}
          </BannerFlex>
        </Wrapper>
      </a>
    ) : (
      <a href="" target="_blank">
        <Wrapper>
          <BannerFlex className={icon ? "icon" : ""}>
            <div>
              <h3>{text}</h3>
            </div>
            {icon ? (
              <div>
                <img src={icon}></img>
              </div>
            ) : (
              ""
            )}
          </BannerFlex>
        </Wrapper>
      </a>
    )}
  </BannerDiv>
);

export default IconBanner;
