import * as React from "react";
//import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import FullPageImgHeader from "../components/index/FullPageImgHeader";
import IconBanner from "../components/index/IconBanner";
import Background4Grid from "../components/index/Background4Grid";
import Title from "../components/Title";
import ProjekteGrid from "../components/ProjekteGrid";
import ProjektItem from "../components/ProjektItem";
import DefaultLayout from "../layouts/default";
import HorizontalWrapper from "../components/HorizontalWrapper";
import GridWrapper from "../components/GridWrapper";
import { Helmet } from "react-helmet";

interface IndexQueryProps {
  cms: {
    startseite: {
      title: string;
      gridBildLinks: [
        {
          url: string;
        }
      ];
      gridBildRechts: [
        {
          url: string;
        }
      ];
      gridTextLinks: string;
      headerLead: string;
      headerTitle: string;
      bannerIcon: [
        {
          url: string;
        }
      ];
      bannerLink: [
        {
          url: string;
        }
      ];
      mainImage: [
        {
          url: string;
        }
      ];
      bannerText: string;
      bannerDownload: [
        {
          url: string;
        }
      ];
    };
    projekte: [
      {
        title: string;
        url: string;
        mainImageProjekt: [
          {
            url: string;
          }
        ];
      }
    ];
    fachbereiche: [
      {
        title: string;
        uri: string;
      }
    ];
  };
}

const IndexPage = () => {
  const data: IndexQueryProps = useStaticQuery(graphql`
    query {
      cms {
        startseite: entry(type: "startseite") {
          title
          ... on CMS_inhalt_startseite_Entry {
            gridBildLinks {
              url
            }
            gridBildRechts {
              url
            }
            gridTextLinks
            headerLead
            headerTitle
            bannerIcon {
              url
            }
            bannerLink {
              uri
            }
            mainImage {
              url
            }
            bannerText
            bannerDownload {
              url
            }
          }
        }

        projekte: entries(type: "projekt", featured: true) {
          title
          uri

          ... on CMS_inhalt_projekt_Entry {
            mainImageProjekt {
              url
            }
          }
        }

        fachbereiche: entries(type: "fachbereich") {
          title
          uri
        }
      }
    }
  `);
  const entry = data.cms.startseite;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <FullPageImgHeader
        title={entry.headerTitle}
        lead={entry.headerLead}
        image={entry.mainImage[0].url}
      />
      <IconBanner
        text={entry.bannerText}
        icon={entry.bannerIcon[0] ? entry.bannerIcon[0].url : null}
        downloadLink={
          entry.bannerDownload.length > 0 ? entry.bannerDownload[0].url : ""
        }
        link={entry.bannerLink.length > 0 ? entry.bannerLink[0].uri : ""}
      />
      <Background4Grid
        textL={entry.gridTextLinks}
        fachbereiche={data.cms.fachbereiche}
        imgL={entry.gridBildLinks[0].url}
        imgR={entry.gridBildRechts[0].url}
      />
      <HorizontalWrapper>
        <Title spacingTop>Aktuelle Projekte</Title>
      </HorizontalWrapper>
      <GridWrapper>
        <ProjekteGrid>
          {/* just filter for newest projets entries */}
          {data.cms.projekte.slice(0, 6).map((entry) => (
            <ProjektItem
              key={entry.uri}
              itemTitle={entry.title}
              itemImg={entry.mainImageProjekt[0].url}
              itemLink={entry.uri}
            />
          ))}
        </ProjekteGrid>
      </GridWrapper>
    </DefaultLayout>
  );
};

export default IndexPage;
