import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";
import { Link } from "gatsby";
import ContentWrapper from "../ContentWrapper";
import { Background } from "react-imgix";
import { imgix } from "../../styles/variables";

interface Background4GridProps {
  textL: string;
  fachbereiche: [
    {
      uri: string;
      title: string;
    }
  ];
  imgL: string;
  imgR: string;
}

const FourGrid = styled.div`
  background: ${colors.brand};
  display: flex;
  flex-direction: column;

  > *,
  a {
    color: white;
    min-height: 390px;
  }

  > div:nth-of-type(2) {
    background-image: ${(props) => "url(" + props.imgR + ")"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  > div:nth-of-type(3) {
    background-image: ${(props) => "url(" + props.imgL + ")"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  > div:nth-of-type(1),
  > div:nth-of-type(4) {
    > * {
      width: 100%;
      max-width: 592px;
    }
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  > div:nth-of-type(2) {
    order: 1;
  }
  > div:nth-of-type(1) {
    order: 2;
  }
  > div:nth-of-type(3) {
    order: 3;
  }
  > div:nth-of-type(4) {
    order: 4;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;

    > div:nth-of-type(2) {
      order: 2;
    }
    > div:nth-of-type(1) {
      order: 1;
    }
  }
`;

const Background4Grid: React.FC<Background4GridProps> = ({
  textL,
  fachbereiche,
  imgL,
  imgR,
}) => {
  return (
    <FourGrid imgR={imgR} imgL={imgL}>
      <ContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: textL }}></div>
      </ContentWrapper>
      <Background src={imgR} imgixParams={imgix.gridImgParams}>
        &nbsp;
      </Background>
      <Background src={imgL} imgixParams={imgix.gridImgParams}>
        &nbsp;
      </Background>
      <ContentWrapper>
        <h4>Unsere Fachbereiche</h4>
        <br />
        <ul>
          {fachbereiche.map((entry) => (
            <li key={entry.uri}>
              <Link to={"/" + entry.uri}>{entry.title}</Link>
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </FourGrid>
  );
};

export default Background4Grid;
