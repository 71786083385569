import React from "react";
import styled from "styled-components";
import { imgix } from "../../styles/variables";

import { heights } from "../../styles/variables";

import HeaderBanner from "./HeaderBanner";
import ImgixImg from "../ImgixImg";

interface FullPageImgHeaderProps {
  title: string;
  lead: string;
  image: string;
}

const ImgHeader = styled.div`
  height: calc(100vh - ${heights.nav}px);
  width: 100vw;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const FullPageImgHeader: React.FC<FullPageImgHeaderProps> = ({
  title,
  lead,
  image,
}) => {
  return (
    <ImgHeader>
      <div>
        <ImgixImg src={image} imgixParams={imgix.fullHeaderImgParams} />
        <HeaderBanner title={title} lead={lead} />
      </div>
    </ImgHeader>
  );
};

export default FullPageImgHeader;
