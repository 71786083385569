import React from "react";
import styled from "styled-components";
import Wrapper from "../Wrapper";

import { colors } from "../../styles/variables";

interface HeaderBannerProps {
  title: string;
  lead: string;
}

const Banner = styled.div`
  background: ${colors.transparentBg};
  width: 100vw;
  height: auto;
  position: absolute;
  top: auto;
  bottom: 0;
  color: white;
  hyphens: auto;
  word-wrap: break-word;
  z-index: 1;
`;

const HeaderBanner: React.FC<HeaderBannerProps> = ({ title, lead }) => (
  <Banner>
    <Wrapper>
      <h1>{title}</h1>
      <p>{lead}</p>
    </Wrapper>
  </Banner>
);

export default HeaderBanner;
